import React from 'react';
import { Button, Modal } from 'react-bootstrap';

class InfoModal extends React.Component {
    handleConfirm = () => {
        this.props.onConfirm(this.props.id);
    }          
    handleClose = () => {
        this.props.onDismiss();
    }    
    render() {
        return (           
            <Modal show={this.props.show} onHide={this.handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>{this.props.title}</Modal.Title>
              </Modal.Header>
              <Modal.Body>{this.props.text}</Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={this.handleClose}>
                  Cancel
                </Button>
                <Button variant="primary" onClick={this.handleConfirm}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal>
        )
    }
}

export default InfoModal;