import React from 'react';

import { Button, Modal, ControlLabel, FormControl, FormGroup } from 'react-bootstrap';

class CreateRequestModal extends React.Component {
    state = {
      model: '',
      map: '',
      url: ''
    }     
    handleModelChange = (event) => {
      this.setState({ model: event.target.value });
    }    
    handleMapChange = (event) => {
      this.setState({ map: event.target.value });
    }   
    handleUrlChange = (event) => {
      this.setState({ url: event.target.value });
    }                  
    handleConfirm = () => {
        this.props.onConfirm(this.state.model, this.state.map, this.state.url);
    }          
    handleClose = () => {
        this.props.onDismiss();
    }    
    render() {
        return (           
            <Modal show={this.props.show} onHide={this.handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Create new request</Modal.Title>
              </Modal.Header>
              <Modal.Body>
              <FormGroup className="mb-3">
                <ControlLabel>Model</ControlLabel>
                <FormControl
                  type="text"
                  value={this.state.model}
                  placeholder="Enter model"
                  onChange={this.handleModelChange}
                />
              </FormGroup>
              <FormGroup className="mb-3">
                <ControlLabel>Map</ControlLabel>
                <FormControl
                  type="text"
                  value={this.state.map}
                  placeholder="Enter map"
                  onChange={this.handleMapChange}
                />
              </FormGroup>
              <FormGroup className="mb-3">
                <ControlLabel>Dataset</ControlLabel>
                <FormControl
                  type="text"
                  value={this.state.url}
                  placeholder="Enter dataset url"
                  onChange={this.handleUrlChange}
                />
              </FormGroup>                            
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={this.handleClose}>
                  Cancel
                </Button>
                <Button variant="primary" onClick={this.handleConfirm}>
                  Send
                </Button>
              </Modal.Footer>
            </Modal>
        )
    }
}

export default CreateRequestModal;