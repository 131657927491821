import React from 'react';
import {
    Box, Button, Content, Col, DataTable, Infobox, Row
} from 'adminlte-2-react';

import CreateRequestModal from './modals/CreateRequestModal';
import InfoModal from './modals/InfoModal';




class Requests extends React.Component {
    state = {
        showRequestModal: false,
        showCommitModal: false,
        showDeleteModal: false,        
        createRequestModal: false,
        infoModalId: 0
    }    

    listOptions = {
        paging: true,
        lengthChange: false,
        searching: false,
        ordering: true, 
        condensed: true,
        info: true,
        autoWidth: false,
        order: [[5, 'desc']]
    }

    latestOrdersColumns = [{
        title: 'Id',
        data: '_id',
        render: data => <a href="#" className="select-request">{data}</a>,
    }, {
        title: 'Mapping',
        data: 'map',
        visible: false,
    }, {
        title: 'Model',
        data: 'model',
    }, {
        title: 'Status',
        data: 'status',
        orderable: false,
        render: (data, rowData) => <span className={"label label-" + ((data === "Success") ? "success" : ((data === 'Error') ? "danger" : "warning"))}>{data}</span>,
    }, {
        title: 'Orion',
        data: 'orion',
        orderable: false,
        render: (data, rowData) => <span className={"label label-" + ((data === "Success") ? "success" : ((data === 'Error') ? "danger" : ((data === 'Pending')?"warning":"default")))}>{data}</span>,
    }, {
        title: 'Start date',
        data: 'startDate',
    }, {
        title: 'End date',
        data: 'endDate',
    }, {
        title: '',
        data: '_id',
        orderable: false,
        render: (data, rowData) => <a href="#" className="commit-request label label-warning">Commit</a>
    },
    {
        title: '',
        data: '_id',
        orderable: false,
        render: (data, rowData) => <a href="#" className="delete-request label label-danger">Delete</a>
    }];

    // Commit to Orion
    onCommitRequest = (id, rowIndex, rowData) => {
        this.showCommitModal(id);
    }         

    showCommitModal = (id) => {
        this.setState({ showCommitModal: true, infoModalId: id });
    }

    hideCommitModal = () => {
        this.setState({ showCommitModal: false });
    }    

    handleCommitOk = (id) => {
        this.props.onCommitRequest(id);
        this.hideCommitModal();
    }   

    handleCommitNo = (id) => {
        this.hideCommitModal();
    }         

    // Delete request start
    onDeleteRequest = (id, rowIndex, rowData) => {
        console.log("on delete request id: " + id);
        this.showDeleteModal(id);
    } 

    showDeleteModal = (id, rowIndex, rowData) => {
        this.setState({ showDeleteModal: true, infoModalId: id });
    }
    
    hideDeleteModal = () => {
        this.setState({ showDeleteModal: false });
    }

    handleDeleteOk = (id) => {
        this.props.onDeleteRequest(id);
        this.hideDeleteModal();
    }   

    handleDeleteNo = () => {
        this.hideDeleteModal();
    }  

    // View request
    onSelectRequest = (id, rowIndex, rowData) => {
        console.log("on select request id: " + id);
        this.showRequestModal(id);
    }   

    showRequestModal = (id) => {
        this.setState({ showRequestModal: true, infoModalId: id });
    }

    hideRequestModal = () => {
        this.setState({ showRequestModal: false });
    }

    handleCreateRequest = (id) => {
        this.showCreateRequestModal();
    }  

    handleRequestOk = (id) => {
        this.hideRequestModal();
    }   

    handleRequestNo = (id) => {
        this.hideRequestModal();
    }       

    // Create request
    showCreateRequestModal = (id, rowIndex, rowData) => {
        this.setState({ createRequestModal: true  });
    }

    hideCreateRequestModal = () => {
        this.setState({ createRequestModal: false });
    }    

    handleCreateRequestOk = (map, model, url) => {
        this.props.onCreateRequest(map, model, url);
        this.hideCreateRequestModal();
    }   

    handleCreateRequestNo = (id) => {
        this.hideCreateRequestModal();
    }          

    render() {
        return (
           
            <>  
                      
            <Content title="Requests list">     
                <InfoModal show={this.state.showRequestModal} id={this.state.infoModalId} text="Do you really want to delete the request?" onConfirm={this.handleRequestOk} onDismiss={this.handleRequestNo} title='View request' modalType='info'></InfoModal>                                         
                <InfoModal show={this.state.showDeleteModal}  id={this.state.infoModalId} text="Do you really want to delete the request?" onConfirm={this.handleDeleteOk} onDismiss={this.handleDeleteNo} title='Delete request' modalType='info'></InfoModal>                                         
                <InfoModal show={this.state.showCommitModal}  id={this.state.infoModalId} text="Do you really want to send the request to Orion broker?" onConfirm={this.handleCommitOk} onDismiss={this.handleCommitNo} title='Commit request' modalType='warning'></InfoModal>                                                         
                <CreateRequestModal show={this.state.createRequestModal} onConfirm={this.handleCreateRequestOk} onDismiss={this.handleCreateRequestNo} title='Delete request' modalType='info'></CreateRequestModal>                                           
                <Row>
                    <Col md={3}>
                
                        <Infobox icon="fa-copy" color="navy" number={this.props.stats.total} text="Total"/>
                    </Col>
                    <Col md={3}>
                        <Infobox icon="fa-thumbs-up" color="green" number={this.props.stats.success} text="Success"/>
                    </Col>
                    <Col md={3}>
                        <Infobox icon="fa-sync-alt" color="yellow" number={this.props.stats.pending} text="Pending"/>
                    </Col>
                    <Col md={3}>
                        <Infobox icon="fa-times" color="red" number={this.props.stats.failed} text="Failed"/>
                    </Col>                                                            
                </Row>
                <Row style={ { "marginTop": "30px" } }>
                    <Col md={12}>
                        <Button type="success" text="Create a new request" pullLeft onClick={this.showCreateRequestModal}/>
                    </Col>                    
                </Row>
                <Row style={ { "marginTop": "10px" } }>
                    <Col md={12}>
                        <Box type="success" title="Latest Requests">
                            <DataTable responsive columns={this.latestOrdersColumns} data={this.props.requests} options={this.listOptions} onClickEvents={ { "select-request": this.onSelectRequest, "commit-request": this.onCommitRequest, "delete-request": this.onDeleteRequest }}/>
                        </Box>
                    </Col>
                </Row>    
            </Content>
            </>               
        );
    }
}

export default Requests;