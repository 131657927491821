import React from 'react';
import axios from 'axios';

import AdminLTE, { Sidebar } from 'adminlte-2-react';

import Requests from './Requests';

const { Item, Header } = Sidebar;

class App extends React.Component {
    state = { requests: [], stats: {} };
    ws = null;
    reconnectTimer = null;
    constructor(props) {
        super(props);
        this.baseUrl = window.location.href;
    }
    onRequestDetails(id) {
        console.log(id);
    }
    onCreateRequest = async (model, map, url) => {
        console.log("Creating item # model: " + model + " # map: " + map + " # url: " + url);
        await axios.post(this.baseUrl + 'api/conversion/request', {
            model: model,
            map: map,
            url: url
        });
        var listResponse = await axios.get(this.baseUrl + 'api/conversion/list');
        var statsResponse = await axios.get(this.baseUrl + 'api/conversion/stats');
        this.setState({ requests: listResponse.data.requests, stats: statsResponse.data.stats });        
    }    
    onCommitRequest = async (id) => {
        console.log("Committing item to orion # id: " + id);
        await axios.post(this.baseUrl + 'api/conversion/commit', {
            id: id
        });
        var listResponse = await axios.get(this.baseUrl + 'api/conversion/list');
        var statsResponse = await axios.get(this.baseUrl + 'api/conversion/stats');
        this.setState({ requests: listResponse.data.requests, stats: statsResponse.data.stats });         
    }
    onSelectRequest = async (id) => {
        console.log("Selected item # id: " + id);
    }
    onDeleteRequest = async (id) => {
        await axios.delete(this.baseUrl + 'api/conversion/delete?id=' + id);
        var listResponse = await axios.get(this.baseUrl + 'api/conversion/list');
        var statsResponse = await axios.get(this.baseUrl + 'api/conversion/stats');
        this.setState({ requests: listResponse.data.requests, stats: statsResponse.data.stats });
    }    
    componentDidMount() {
        this.fetchData();
    }
    fetchData = async () => {
        console.log("Server url: " + window.location.href);
        var listResponse = await axios.get(this.baseUrl + 'api/conversion/list');
        var statsResponse = await axios.get(this.baseUrl + 'api/conversion/stats');
        this.setState({ requests: listResponse.data.requests, stats: statsResponse.data.stats });
        setTimeout(this.fetchData, 10000);
    }
    render() {
        return (
            <AdminLTE title={["Ngsi", "Mapper"]} browserTitle="Ngsi Mapper" theme="blue">
                <Sidebar.Core>
                    <Header text="MAIN NAVIGATION" />
                    <Item active text="Requests" icon="fa-tachometer-alt">
                        <Item text="List" to="/" activeOn="^/$" />
                    </Item>
                </Sidebar.Core>
                <Requests path="/" stats={this.state.stats} requests={this.state.requests} onCreateRequest={this.onCreateRequest} onCommitRequest={this.onCommitRequest} onDeleteRequest={this.onDeleteRequest} onSelectRequest={this.onSelectRequest}/>
            </AdminLTE>
        );
    }
};

export default App;